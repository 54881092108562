:root {
    --color-blue-primary: #042c4c;
    --color-blue-vivid: #1698ff;
    --color-blue-black-pearl: #00182c;
    --color-black-alt-1: #00000014;
    --color-black: #000;
    --color-white: #fff;
    --color-white-mischka: #a0aec0;
    --color-blue-dark-slate: #0f2a41;
    --color-blue-medium-cobalt: #025aa5;
    --color-blue-faint-cloud: #a7b4be3f;
}

.product-builder-main {
    margin-top: 0;
}

.intro-wrapper {
    align-items: center;
    background: var(--color-blue-black-pearl);
    background: linear-gradient(
        0deg,
        var(--color-blue-black-pearl),
        var(--color-blue-primary) 24%,
        var(--color-blue-primary) 57%,
        var(--color-blue-black-pearl)
    );
    display: flex;
    height: 876px;
    justify-content: space-between;
    padding: 176px 15% 10%;
}

.intro-wrapper .intro-wrapper-text {
    max-width: 500px;
    text-align: start;
}

.intro-wrapper .intro-wrapper-text h1 {
    color: var(--color-white);
    font-size: 3.1rem;
    font-weight: 600;
    line-height: 59px;
    margin-bottom: 15px;
    text-transform: uppercase;
}

.intro-wrapper .intro-wrapper-text p {
    color: var(--color-white);
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
}

.intro-wrapper .intro-wrapper-image {
    height: auto;
    margin-top: 80px;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    width: 50%;
}

@media (max-width: 1650px) {
    .intro-wrapper {
        padding: 0 10%;
    }
    .intro-main-container {
        padding: 60px 10%;
    }
    .intro-wrapper-image {
        margin-left: 50px;
    }
}

.solution-section-wrapper .headline-wrapper {
    bottom: 150px;
    margin: 0 auto;
    max-width: 1220px;
    position: relative;
    width: 95%;
}

.solution-section-wrapper .headline-wrapper h2 {
    color: var(--color-white);
    font-size: 2.3rem;
    font-weight: 700;
    line-height: 47px;
}

.solution-section-wrapper .headline-wrapper p {
    color: var(--color-white-mischka);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 0;
}

.solution-section-wrapper .solution-wrapper {
    bottom: 130px;
    display: flex;
    font-family: sans-serif;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1220px;
    position: relative;
    width: 95%;
}

.solution-section-wrapper .solution-wrapper .form-wrapper {
    background: linear-gradient(145deg, var(--color-blue-dark-slate) 15px, var(--color-white) 0);
    box-shadow: 0 0 34px 0 var(--color-blue-faint-cloud);
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    padding-top: 10px;
    width: 30%;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .options-wrapper {
    display: flex;
    flex-direction: column;
    padding: 13px 28px 13px 0;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .software {
    padding-top: 10px;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .services {
    padding-top: 25px;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .link-wrapper {
    padding-top: 0;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .cant-find-btn {
    align-self: center;
    box-shadow: 0 1px 2px 2px var(--color-black-alt-1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
    margin-left: 10px;
    padding: 0.7rem 1.7rem;
    transition: none;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .cant-find-btn:focus {
    outline: none;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .react-switch {
    margin-left: 4px;
    vertical-align: middle;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .react-switch-handle {
    border: 2px solid var(--color-white);
}

.solution-section-wrapper .solution-wrapper .form-wrapper .switch-label {
    color: var(--color-blue-primary);
    font-size: 1.1rem;
    font-weight: 500;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .form-solution-link {
    background-color: var(--color-white);
    border: 0;
    border-bottom: 3px solid var(--color-blue-vivid);
    color: var(--color-blue-vivid);
    font-size: 1rem;
    font-weight: 300;
    padding-bottom: 6px;
    text-decoration: none;
    text-transform: uppercase;
    width: 240px;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .form-solution-link:focus {
    outline: 0;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .hardware-radio {
    padding: 10px 0 0;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .custom-control {
    margin-bottom: 5px;
}

.solution-section-wrapper .solution-wrapper .form-wrapper .switch-wrapper {
    display: flex;
    justify-content: space-between;
}

.solution-section-wrapper .solution-wrapper .form-wrapper h2 {
    color: var(--color-blue-primary);
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.solution-section-wrapper .solution-wrapper .form-wrapper label {
    color: var(--color-blue-primary);
    font-size: 1.1rem;
    font-weight: 300;
}

.solution-section-wrapper .solution-wrapper .img-wrapper {
    align-items: flex-end;
    background: var(--color-white);
    box-shadow: 0 0 34px 0 var(--color-blue-faint-cloud);
    display: flex;
    flex-direction: column;
    width: 70%;
}

.solution-section-wrapper .solution-wrapper .img-wrapper .solution-not-loaded {
    margin-top: 200px;
    position: absolute;
    z-index: 10;
}

.solution-section-wrapper .solution-wrapper .img-wrapper img {
    height: 100%;
    justify-self: center;
    width: 100%;
}

.solution-section-wrapper .solution-wrapper .img-wrapper .bottom-img {
    position: absolute;
}

.solution-section-wrapper .solution-wrapper .img-wrapper .img-btn {
    box-shadow: 0 0 34px 0 var(--color-blue-faint-cloud);
}

@media (max-width: 1100px) {
    .solution .solution-block .solution-form .form-solution-link {
        font-size: 0.87rem;
    }
    .solution .solution-block .solution-form .solution-wrap {
        padding: 0 28px 13px 0;
    }
}

@media (max-width: 1270px) {
    .black-block h2,
    .black-block p {
        margin-left: 30px;
    }
}

@media (max-width: 992px) {
    .solution-section-wrapper {
        height: 1250px;
    }
    .solution-section-wrapper .solution-wrapper {
        flex-direction: column;
        max-height: none;
        width: 100%;
    }
    .solution-section-wrapper .solution-wrapper .form-wrapper {
        width: 100%;
    }
    .solution-section-wrapper .solution-wrapper .form-wrapper .solution-wrapper {
        font-size: 1rem;
    }
    .solution-section-wrapper .solution-wrapper .img-wrapper {
        box-shadow: none;
        flex-direction: column;
        width: 100%;
    }
    .solution-section-wrapper .solution-wrapper .img-wrapper img {
        left: 0;
        margin-top: 20px;
        position: static;
        width: 100%;
        z-index: 2;
    }
    .solution-section-wrapper .solution-wrapper .img-wrapper .bottom-img {
        height: 500px;
        left: 0;
        z-index: 1;
    }
    .solution-section-wrapper .solution-wrapper .img-wrapper .img-btn {
        background: linear-gradient(-30deg, var(--color-white) 15px, var(--color-blue-vivid) 0);
        box-shadow: none;
        font-size: 1.37rem;
        line-height: 72px;
        margin: 30px auto 0;
        min-width: 275px;
        position: static;
    }
    .solution-section-wrapper .solution-wrapper .img-wrapper .mobile-position {
        font-size: 1.1rem;
        line-height: 19px;
        margin: auto;
        padding: 20px 0 0;
    }
}

@media (max-width: 600px) {
    .solution-section-wrapper {
        margin-bottom: 100px;
    }
}

.btn {
    cursor: pointer;
}
.btn[disabled] {
    cursor: not-allowed;
}
.btn-primary {
    background-color: var(--color-blue-vivid);
    border-color: var(--color-blue-vivid);
    color: var(--color-white);
    line-height: 20px;
}
.btn-primary:hover {
    background-color: var(--color-blue-medium-cobalt);
    border-color: var(--color-blue-medium-cobalt);
}
