:root {
    --color-black: #000000;
    --color-gray-light: #888888;
    --color-white: #ffffff;
    --color-blue-primary: #042c4c;
    --color-blue-accent: #1976d2;
    --color-red-grape: #d93249;
}

#json-editor {
    font-family: "Segoe UI", sans-serif;
    color: var(--color-black);
}
#json-editor .je-indented-panel {
    border: none;
    padding: 0;
}
#json-editor .je-modal {
    padding: 0;
    box-shadow: none;
    padding: 5px;
    padding-right: 10px;
    border-color: var(--color-gray-light);
    margin-top: 10px;
}
#json-editor .je-textarea {
    border: none;
}
#json-editor button {
    background-color: var(--color-blue-primary);
    color: var(--color-white);
    padding: 4px 6px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 4px;
    margin: 2px;
}
#json-editor .btn:hover {
    background-color: var(--color-blue-accent);
}

#json-editor input[type="text"],
#json-editor select {
    padding: 10px;
    margin: 5px 0 15px 0;
    border: 1px solid var(--color-gray-light);
    border-radius: 4px;
    width: 95%;
    font-style: normal;
}

#json-editor label {
    margin-top: 10px;
    margin-bottom: 5px;
    display: block;
}
#json-editor .error {
    color: var(--color-red-grape);
    font-size: 14px;
    margin: 5px 0;
}

#json-editor .card {
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 4px;
}

#json-editor .header {
    background-color: var(--color-white);
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 4px;
    font-size: 18px;
    color: var(--color-black);
}
